import 'aos/dist/aos.css';

import React, { useEffect } from 'react';

import AOS from 'aos';
import { Trans } from '@lingui/macro';
import pic3x1 from '../assets/images/pic03@1x.jpg';
import pic3x1webp from '../assets/images/pic03@1xwebp.webp';
import pic3x2 from '../assets/images/pic03@2x.jpg';
import pic3x2webp from '../assets/images/pic03@2xwebp.webp';
import pic3x3 from '../assets/images/pic03@3x.jpg';
import pic3x3webp from '../assets/images/pic03@3xwebp.webp';
import pic3x4 from '../assets/images/pic03@4x.jpg';
import pic3x4webp from '../assets/images/pic03@4xwebp.webp';
import pic3x5 from '../assets/images/pic03@5x.jpg';
import pic3x5webp from '../assets/images/pic03@5xwebp.webp';
import { withI18n } from '@lingui/react';

function Work() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div className="image" data-aos="fade-up">
        <picture>
          <source
            srcSet={`${pic3x1webp} 384w, ${pic3x2webp} 768w, ${pic3x3webp} 1152w, ${pic3x4webp} 1536w, ${pic3x5webp} 1920w`}
            type="image/webp"
          />
          <img
            srcSet={`${pic3x1} 384w, ${pic3x2} 768w, ${pic3x3} 1152w, ${pic3x4} 1536w, ${pic3x5} 1920w`}
            alt=""
          />
        </picture>
      </div>
      <div className="content" data-aos="fade-right">
        <h2 style={{ fontSize: '1.4em', fontStyle: 'bold', fontWeight: 900 }}>
          <Trans>Our work</Trans>
        </h2>
        <p style={{ fontSize: '1.2em', fontWeight: 30 }}>
          <Trans>
            We have worked on several apps in different technologies like iOS,
            Android and React Native, as well as ReactJS for web. We will show
            you some our previous work, contact with us!
          </Trans>
        </p>
      </div>
    </>
  );
}

export default withI18n()(Work);
