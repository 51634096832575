import { ErrorMessage, Field, Form, Formik } from 'formik';

import React from 'react';
import { Trans } from '@lingui/macro';
import { withI18n } from '@lingui/react';

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

function Formulary(props) {
  const { i18n } = props;
  const { _language } = i18n;

  return (
    <>
      <header>
        <h2 style={{ fontSize: '1.45em', fontWeight: 900 }}>
          <Trans>LET'S GET IN TOUCH</Trans>
        </h2>
        <p style={{ fontSize: '18px' }}>
          <Trans>
            Let's talk, no strings attached, tell us your idea and we'll do our
            best to provide with the best service and support
          </Trans>
        </p>
      </header>

      <Formik
        initialValues={{
          'bot-field': '',
          'form-name': 'contact',
          name: '',
          email: '',
          message: '',
        }}
        onSubmit={(values, actions) => {
          fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({ 'form-name': 'contact', ...values }),
          })
            .then(() => {
              alert('Your message was sent. We will contact you soon.');
              actions.resetForm();
            })
            .catch(() => {
              alert('Error');
            })
            .finally(() => actions.setSubmitting(false));
        }}
        validate={values => {
          const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
          const errors = {};
          if (!values.name) {
            errors.name =
              _language === 'es' ? 'Nombre Requerido' : 'Name Required';
          }
          if (!values.email || !emailRegex.test(values.email)) {
            errors.email =
              _language === 'es'
                ? 'Email Valido Requerido'
                : 'Valid Email Required';
          }
          if (!values.message) {
            errors.message =
              _language === 'es' ? 'Mensaje Requerido' : 'Message Required';
          }
          return errors;
        }}
      >
        {() => (
          <Form
            name="contact"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <Field type="hidden" name="bot-field" />
            <Field type="hidden" name="form-name" />
            <div className="row gtr-uniform">
              <div className="col-6 col-12-xsmall">
                <Field
                  type="text"
                  name="name"
                  placeholder={i18n._('Name')}
                  style={{ color: 'white' }}
                />
                <ErrorMessage
                  component="div"
                  name="name"
                  style={{ color: 'red' }}
                />
              </div>

              <div className="col-6 col-12-xsmall">
                <Field
                  type="email"
                  name="email"
                  placeholder="Email"
                  style={{ color: 'white' }}
                />
                <ErrorMessage
                  component="div"
                  name="email"
                  style={{ color: 'red' }}
                />
              </div>

              <div className="col-12">
                <Field
                  name="message"
                  component="textarea"
                  placeholder={i18n._('Enter your message')}
                  rows="6"
                  style={{ color: 'white' }}
                />
                <ErrorMessage
                  component="div"
                  name="message"
                  style={{ color: 'red' }}
                />
                <div></div>
              </div>
              <div className="col-12">
                <input
                  type="submit"
                  value={_language === 'es' ? 'Enviar Mensaje' : 'Send Message'}
                  aria-label={
                    _language === 'es' ? 'Enviar Mensaje' : 'Send Message'
                  }
                  className="primary"
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default withI18n()(Formulary);
