/*!

=========================================================
* BLK Design System PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import 'aos/dist/aos.css';

import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import React, { useEffect } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import AOS from 'aos';
import AnimationsBoxes from './Animations';
import Img from 'gatsby-image';
import { Trans } from '@lingui/macro';

// reactstrap components

//image imports

// core components
function Teams() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <StaticQuery
      query={graphql`
        query teamImageQuery {
          daniel2: file(name: { eq: "daniel2@5x" }) {
            name
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }

          luisiana: file(name: { eq: "luisiana@5x" }) {
            name
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          daniel: file(name: { eq: "daniel@5x" }) {
            name
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }

          manuel: file(name: { eq: "manuel@5x" }) {
            name
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }

          tronco: file(name: { eq: "tronco@5x" }) {
            name
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }

          vicente: file(name: { eq: "vicente@5x" }) {
            name
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <>
          <AnimationsBoxes />
          <div className="cd-section" id="teams">
            {/* ********* TEAM 2 ********* */}
            <div className="team-2">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto text-center" md="8">
                    <h2
                      className="title"
                      style={{
                        fontSize: '1.4em',
                        fontStyle: 'bold',
                        fontWeight: 900,
                      }}
                    >
                      <Trans>The Team</Trans>
                    </h2>
                    <h4
                      className="description"
                      style={{ fontSize: '15px', fontWeight: '50' }}
                    >
                      <Trans>Our People</Trans>
                    </h4>
                  </Col>
                </Row>
                <Row>
                  <Col
                    md="4"
                    data-aos="fade-up"
                    data-aos-offset="300"
                    data-aos-easing="ease-in-sine"
                  >
                    <Card className="card-profile">
                      <div className="card-image">
                        <h4 className="title">Manuel Ibáñez</h4>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <Img
                            fluid={data.manuel.childImageSharp.fluid}
                            className="img img-raised rounded"
                          />
                        </a>
                      </div>
                      <CardBody>
                        <hr className="line-primary" />
                        <h3 className="job-title" style={{ display: 'block' }}>
                          CEO - Backend Developer
                        </h3>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col
                    md="4"
                    data-aos="fade-up"
                    data-aos-offset="300"
                    data-aos-easing="ease-in-sine"
                  >
                    <Card className="card-profile">
                      <div className="card-image">
                        <h4 className="title">Daniel Sierra</h4>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <Img
                            fluid={data.daniel.childImageSharp.fluid}
                            className="img img-raised rounded"
                          />
                        </a>
                      </div>
                      <CardBody>
                        <hr className="line-primary" />
                        <h3 className="job-title" style={{ display: 'block' }}>
                          {' '}
                          CTO - Frontend Developer
                        </h3>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col
                    md="4"
                    data-aos="fade-up"
                    data-aos-offset="300"
                    data-aos-easing="ease-in-sine"
                  >
                    <Card className="card-profile">
                      <div className="card-image" style={{}}>
                        <h4 className="title">Vicente Quesada</h4>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <Img
                            fluid={data.vicente.childImageSharp.fluid}
                            className="img img-raised rounded"
                          />
                        </a>
                      </div>
                      <CardBody>
                        <hr className="line-primary" />
                        <h3
                          className="job-title"
                          style={{ marginBottom: 38, display: 'block' }}
                        >
                          React Developer
                        </h3>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col
                    md="4"
                    data-aos="fade-up"
                    data-aos-offset="300"
                    data-aos-easing="ease-in-sine"
                  >
                    <Card className="card-profile">
                      <div className="card-image">
                        <h4 className="title">Daniel Febres</h4>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <Img
                            fluid={data.daniel2.childImageSharp.fluid}
                            className="img img-raised rounded"
                          />
                        </a>
                      </div>
                      <CardBody>
                        <hr className="line-primary" />
                        <h3 className="job-title" style={{ display: 'block' }}>
                          React Developer
                        </h3>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col
                    md="4"
                    data-aos="fade-up"
                    data-aos-offset="300"
                    data-aos-easing="ease-in-sine"
                  >
                    <Card className="card-profile">
                      <div className="card-image">
                        <h4 className="title">Luisana Alcazar</h4>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <Img
                            fluid={data.luisiana.childImageSharp.fluid}
                            className="img img-raised rounded"
                          />
                        </a>
                      </div>
                      <CardBody>
                        <hr className="line-primary" />
                        <h3 className="job-title" style={{ display: 'block' }}>
                          UX / Designer
                        </h3>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col
                    md="4"
                    data-aos="fade-up"
                    data-aos-offset="300"
                    data-aos-easing="ease-in-sine"
                  >
                    <Card className="card-profile">
                      <div className="card-image">
                        <h4 className="title">Sergio Blanco</h4>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <Img
                            fluid={data.tronco.childImageSharp.fluid}
                            className="img img-raised rounded"
                          />
                        </a>
                      </div>
                      <CardBody>
                        <hr className="line-primary" />
                        <h3 className="job-title" style={{ display: 'block' }}>
                          Mobile Developer
                        </h3>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
            {/* ********* END TEAM 2 ********* */}
          </div>{' '}
        </>
      )}
    />
  );
}

export default Teams;
