import React from 'react';
import styled from 'styled-components';

const AnimationsBoxes = () => {
  const boxes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  return (
    <Boxes>
      {boxes.map(box => (
        <div key={box} className="box"></div>
      ))}
    </Boxes>
  );
};

export default AnimationsBoxes;

const Boxes = styled.div`
  .box {
    border-radius: 0;
    position: absolute;
    left: 0;
    background-color: #f2f2f2;
    opacity: 0;
    padding: 0;
    animation: animate 15s infinite ease-in-out;
    z-index: 0;
  }
  .box:nth-child(1) {
    width: 75px;
    height: 1px;
    top: 7%;
    animation-delay: 1s;
  }
  .box:nth-child(2) {
    width: 80px;
    height: 1px;
    top: 12%;
    animation-delay: 5s;
  }
  .box:nth-child(3) {
    width: 62px;
    height: 1px;
    top: 18%;
    animation-delay: 7s;
  }
  .box:nth-child(4) {
    width: 80px;
    height: 1px;
    top: 30%;
    animation-delay: 2s;
  }
  .box:nth-child(5) {
    width: 60px;
    height: 1px;
    top: 45%;
    animation-delay: 10s;
  }
  .box:nth-child(6) {
    width: 55px;
    height: 1px;
    top: 50%;
    animation-delay: 5s;
  }
  .box:nth-child(7) {
    width: 70px;
    height: 1px;
    top: 60%;
    animation-delay: 7s;
  }
  .box:nth-child(8) {
    width: 90px;
    height: 1px;
    top: 66%;
    animation-delay: 6s;
  }
  .box:nth-child(9) {
    width: 40px;
    height: 1px;
    top: 78%;
    animation-delay: 6s;
  }
  .box:nth-child(10) {
    width: 50px;
    height: 1px;
    top: 80%;
    animation-delay: 5s;
  }
  .box:nth-child(11) {
    width: 60px;
    height: 1px;
    top: 90%;
    animation-delay: 4s;
  }
  .box:nth-child(12) {
    width: 40px;
    height: 1px;
    top: 96%;
    animation-delay: 7s;
  }

  @keyframes animate {
    0% {
      left: 0;
      opacity: 0;
    }
    10% {
      opacity: 0.2;
    }
    50% {
      width: 10%;
      opacity: 0.3;
    }
    100% {
      left: 80%;
      width: 15%;
      opacity: 0;
    }
  }
`;
