import 'aos/dist/aos.css';

import React, { useEffect } from 'react';

import AOS from 'aos';
import { Trans } from '@lingui/macro';
import pic1x1 from '../assets/images/pic01@1x.jpg';
import pic1x1webp from '../assets/images/pic01@1xwebp.webp';
import pic1x2 from '../assets/images/pic01@2x.jpg';
import pic1x2webp from '../assets/images/pic01@2xwebp.webp';
import pic1x3 from '../assets/images/pic01@3x.jpg';
import pic1x3webp from '../assets/images/pic01@3xwebp.webp';
import pic1x4 from '../assets/images/pic01@4x.jpg';
import pic1x4webp from '../assets/images/pic01@4xwebp.webp';
import pic1x5 from '../assets/images/pic01@5x.jpg';
import pic1x5webp from '../assets/images/pic01@5xwebp.webp';

function What() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div className="image" data-aos="fade-up">
        <picture>
          <source
            srcSet={`${pic1x1webp} 150w, ${pic1x2webp} 229w, ${pic1x3webp} 449w, ${pic1x4webp} 598w, ${pic1x5webp} 748w`}
            type="image/webp"
          />
          <img
            srcSet={`${pic1x1} 150w, ${pic1x2} 229w, ${pic1x3} 449w, ${pic1x4} 598w, ${pic1x5} 748w`}
            alt=""
          />
        </picture>
      </div>
      <div className="content" data-aos="fade-left">
        <h2
          style={{
            fontSize: '1.4em',
            fontStyle: 'bold',
            fontWeight: 900,
          }}
        >
          <Trans>What do we offer?</Trans>
        </h2>
        <p style={{ fontSize: '1.2em', fontWeight: 30 }}>
          <Trans>
            We offer programming services to technological companies looking
            either for programming support or to develop new innovative ideas.
          </Trans>
          <br />
          <br />
          <Trans>
            We are specialized in mobile and web technologies. Our strong
            technologies are React Native, React JS, GraphQL and Ruby on Rails.
            We use agile methodologies, increasing dramatically productivity and
            quality in a short delivery period.
          </Trans>
        </p>
      </div>
    </>
  );
}

export default What;
